// IndividualChat.js
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import ChatEntry from "./ChatEntry";
import Chatbox from "./Chatbox";
import styles from "./listChats.module.css";
import { useNavigate } from "react-router-dom";

const IndividualChat = () => {
  const [chats, setChats] = useState([]);
  const { auth } = useContext(AuthContext);
  console.log("Inside ListChat, before parsingDate");
  const [messages, setMessages] = useState({}); // Define messages state
  const [newChatUserIDs, setNewChatUserIDs] = useState([
    "6864",
    "6880",
    "55",
    "6850",
    "6879",
    "3455",
    "6883",
    "6884",
    "6869",
  ]);
  const [newChatUsernames, setNewChatUsernames] = useState([
    "UmiUni_Chat_Human",
    "Akoasm/Baron",
    "UmiUni_Helper",
    "umiuni_nicholas",
    "Boyu",
    "Dean",
    "XixiangLiu",
    "Chaoran_Chat",
    "Helen_Chat",
  ]);
  const [newEncodedID, setNewencodedID] = useState([
    "k61YQdJq9DbOGpJZ",
    "qPGELe32xrd698jZ",
    "pPy7LDdwpRe1YKXA",
    "ABWJxboQlzegwOL0",
    "z4yJrb2vwJbWLDX7",
    "Py7LDdwpWwe1YKXA",
    "VqQK9b6RA7bEvYnG",
    "mQO5xe7LBwa7rJX0",
    "0Gz3YaOy0gdxqlW1",
  ]);

  const navigate = useNavigate();
  const axios = require("axios");

  const handleSendMessage = async (userID, content) => {
    const encoded_userid_url =
      "https://us.umiuni.com:5002/api/user/encode_userid";
    const request_body = {
      userid: userID,
    };

    try {
      // Wait for the response from the axios post request
      const response = await axios.post(encoded_userid_url, request_body);

      console.log(response.data.result); // Log the response data

      if (response.status === 200) {
        sendMessage(response.data.result, content);
      } else {
        throw new Error("An error occurred when trying to encode the userid");
      }
    } catch (error) {
      throw error;
    }
  };

  const sendMessage = (userID, content) => {
    let data = JSON.stringify({
      userid: auth.userid,
      session: auth.session,
      to_userid: userID,
      content: content,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}api/message/individual_chat_mysql`,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Message sent to user " + userID);
        setMessages({ ...messages, [userID]: "" }); // Clear the message input after sending
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const parsingDate = (timestamp) => {
    // Create a new Date object with the provided timestamp
    const date = new Date(timestamp);

    // Extract the components of the date in the local timezone
    const year = date.getFullYear();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`;
    const day =
      date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    const hours =
      date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
    const minutes =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
    const seconds = date.getSeconds();

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  useEffect(() => {
    //AI Chat section code below:
    // User IDs for the new chats
    const newChatUserIDs = ["6864", "6812"];
    console.log("newChatUserIDs:", newChatUserIDs);

    newChatUserIDs.forEach((userID) => {
      let data = JSON.stringify({
        userid: auth.userid,
        session: auth.session,
        to_userid: userID,
        limit: 1,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}api/message/get`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data[0]) {
            let lastMessage = response.data[0].content;
            let lastMessageTime = response.data[0].send_timestamp;
            axios
              .get(`${process.env.REACT_APP_API_URL}api/user/get`, {
                params: {
                  userid: userID,
                },
              })
              .then((response) => {
                chats = [
                  ...chats,
                  {
                    userid: userID,
                    username: response.data.username,
                    useravatar: response.data.avatar,
                    lastMessage: lastMessage,
                    lastMessageTime: parsingDate(lastMessageTime),
                  },
                ];
                setChats(chats);
              })
              .catch((error) => {
                console.log(error);
                chats = [
                  ...chats,
                  {
                    userid: userID,
                    username: userID,
                    useravatar: null,
                    lastMessage: "error getting",
                    lastMessageTime: "error",
                  },
                ];
                setChats(chats);
              });
          }
        })
        .catch((error) => {
          //catch statement after then
          console.log(error);
          chats = [
            ...chats,
            {
              userid: userID,
              username: userID,
              useravatar: null,
              lastMessage: "error getting",
              lastMessageTime: "error",
            },
          ];
          setChats(chats);
        });
    });
  }, [auth, newChatUserIDs, chats]); // This effect will run again if the auth context changes

  const gridContainerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)", // Adjust number of columns as necessary
    gridGap: "20px", // Space between the grid items
    padding: "20px", // Padding around the grid
  };

  const chatBoxContainerStyle = {
    backgroundColor: "#f0f0f0", // Light background color for each box
    border: "2px solid #ccc", // Border for each box
    padding: "10px", // Padding inside each box
    textAlign: "center", // Centering text and button
  };

  const buttonStyle = {
    fontSize: "16px",
    padding: "10px 20px",
    backgroundColor: "orange",
    color: "white",
    borderRadius: "5px",
    width: "100%", // Ensures the button stretches to the container width
    cursor: "pointer", // Cursor pointer to indicate clickable
    transition: "background-color 0.3s", // Smooth background color transition
  };

  const users = newChatUserIDs.map((id, index) => ({
    id,
    name: newChatUsernames[index],
    encodeid: newEncodedID[index],
  }));

  return (
    <div>
      <div className={styles.headerContainer}>
        <h1 className={styles.header}>Chat with {auth.userid}</h1>
      </div>
      <div className={styles.chatsContainer}>
        {chats.map((chat, index) => (
          <ChatEntry key={index} chat={chat} />
        ))}
      </div>
      {/* Add the chat boxes and send buttons here */}

      <div style={gridContainerStyle}>
        {users.map((user) => (
          <div key={user.id} style={chatBoxContainerStyle}>
            <h2>Chat with {user.id}</h2>
            <button
              style={buttonStyle}
              onClick={() => navigate("/chats/" + user.encodeid)}
            >
              {user.name}
            </button>
          </div>
        ))}
      </div>

      {/* <div style={{ textAlign: 'center', marginTop: '16px' }}>
        <button
          style={buttonStyle}
          onClick={() => navigate('/randomchatroom')}
        >
          Go to Random Chat Room
        </button>
    </div> */}
    </div>
  );
};

export default IndividualChat;
