import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import "./TopUpSuccessPage.css";
import { AuthContext } from "../context/AuthContext"; // Import AuthContext

const TopUpSuccess = () => {
  const { auth } = useContext(AuthContext); // Use `useContext` correctly here
  const { userid } = auth; // Extract `userid` from the context;

  // Function to fetch and alert the balance or error messages
  const fetchAndAlertBalance = async (userid) => {
    try {
      const secretPassword = "my_secret_password"; // Replace with secure handling
  
      if (!userid) {
        alert("You need to log in to view your balance.");
        return;
      }
  
      const response = await fetch("https://us.umiuni.com:5002/api/user/increase_balance", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userid, // Use the `userid` passed as an argument
          secret_password: secretPassword,
          increase_balance: 0, // Set to 0 to fetch current balance
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error("API Error Response:", errorData);
        throw new Error("Failed to fetch balance from server.");
      }
  
      const data = await response.json();
      console.log("API Response:", data);
  
      // Check if the response contains the expected fields
      if (typeof data.new_balance !== "undefined") {
        alert(`Success! Your updated balance is: ${data.new_balance}`);
      } else {
        alert("Error: Unexpected response from the server.");
        console.error("Unexpected API Response:", data);
      }
    } catch (error) {
      alert("An error occurred while fetching your balance. Please try again later.");
      console.error("Error fetching balance:", error);
    }
  };

  // Automatically call the function when the component loads
  useEffect(() => {
    fetchAndAlertBalance(userid); // Pass `userid` to the function
  }, [userid]);

  return (
    <div className="success-page-wrapper">
      <div className="success-page-container">
        <div className="card">
          <div
            style={{
              borderRadius: "200px",
              height: "200px",
              width: "200px",
              background: "#F8FAF5",
              margin: "0 auto",
            }}
          >
            <i className="checkmark">✓</i>
          </div>
          <h1 className="successPage_h1">Success</h1>
          <p className="successPage_p">
            We received your payment and appreciate your business!
            <br />
            If you have any questions, please email: admin@umiuni.com
          </p>
          <Link to="/" className="home-button-link">
            <button className="home-btn">Go Home</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TopUpSuccess;
