import React, { useState } from "react";
import "./ProductDetails.css";

import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";

const ProductDetails = () => {
  // State for the selected price and custom price
  const [totalPrice, setCustomPrice] = useState("");
  const [totalPriceAll, setTotalPrice] = useState("999"); // Default selected value
  const { auth } = useContext(AuthContext);
  const { userid, session } = auth;


  const handleCustomPriceChange = (event) => {
    setCustomPrice(event.target.value);
    // Automatically select the custom price option
    setTotalPrice("custom");
  };

  return (
    <div className="container">

      <div className="product-info">
        <h1>Donate UmiUni Alumni Circle</h1>
        <span className="rating">
          ⭐⭐⭐⭐⭐ 
        </span>
        <p className="description">
          Thank you to all who have generously donated to UmiUni Circle. 🌳🌸🌟
          <br />
          Your kindness not only supports our mission but also reinforces the
          belief that together 👭💜🌼
          <br />
          we can make a big difference! 💪🚀🎯
          <br />
          Here to a brighter and unified future! 🌊💙💖💫💌
        </p>

        <form
          id="checkout-form"
          method="POST"
          action="https://us.umiuni.com:5002/api/payment/charge/stripe">
          <p className="price" id="displayPrice">
            ${(totalPriceAll === "custom" ? totalPrice : totalPriceAll) / 100}
          </p>

          <div className="custom-price-option-wrapper">
            <div className="custom-price-option">
              <div className="custom-price-container">
                <label htmlFor="totalPrice" className="custom-price-label">
                  <p>Donate Amount (e.g. 1000 meaning 1000 cents, meaning $10 in US Dollars)</p>
                  <input
                    type="text"
                    id="totalPrice"
                    name="totalPrice"
                    value={totalPrice}
                    placeholder="Enter custom price $ cent"
                    onChange={handleCustomPriceChange}
                  />
                </label>
              </div>
            </div>
          </div>

          {/* Add hidden input fields for userid, session, and donationText */}
          <input type="hidden" name="userId" value={userid} />
          <input type="hidden" name="session" value={session} />
          <input
            type="text"
            name="donationText"
            placeholder="Enter donation text"
            /* Add any necessary event handlers for donationText input */
          />

          <button type="submit" className="submit-order">
            Submit Order
          </button>
        </form>
      </div>
    </div>
  );
};

export default ProductDetails;
