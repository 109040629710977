import React, { useState, useContext, useRef } from "react";
import { ChatBubble } from "./ChatBubble";
import { AuthContext } from "../context/AuthContext";
import "./GlobalChatRoom.css";

const MedicalAiChat = () => {
    const { auth } = useContext(AuthContext);
    const [chats, setChats] = useState([]);
    const [textBoxValue, setTextBoxValue] = useState("");
    const [name, setName] = useState([]);
    const [pfp, setPfp] = useState([]);
    const wsRef = useRef(null);

    const initWs = () => {
        return new Promise((resolve, reject) => {
            const ws = new WebSocket("https://ai-api.umiuni.com:8989/run-gemma2b");

            ws.onopen = () => {
                console.log("Connected to WebSocket");
                wsRef.current = ws;
                setChats((prevChats) => [
                    ...prevChats,
                    { content: "", timestamp: new Date().toISOString(), sendOut: false, isAccumulated: true }
                ]);
                resolve();
            };

            ws.onmessage = (event) => {
                console.log("Received message:", event.data);
                setChats((prevChats) => {
                    return prevChats.map((chat, index) => {
                        if (index === prevChats.length - 1 && chat.isAccumulated) {
                            return {
                                ...chat,
                                content: `${chat.content}${chat.content ? "\n" : ""}${event.data}`,
                            };
                        }
                        return chat;
                    });
                });

                setName((prevName) => ({
                    ...prevName,
                    "MedicalAiChat": 'MedicalAiChat',
                }));
                setPfp((prevPfp) => ({
                    ...prevPfp,
                    "MedicalAiChat": '94NCQ5GiFeXAVwr3alGqY7Zp9xlzpIGu',
                }));
            };

            ws.onerror = (error) => {
                console.error("WebSocket error:", error);
                reject(error);
            };

            ws.onclose = () => {
                console.log("WebSocket closed");
                wsRef.current = null;
            };

            wsRef.current = ws;
        });
    };

    const handleTextBoxChange = (event) => {
        setTextBoxValue(event.target.value);
    };

    const handleSendClick = async () => {
        const messageContent = textBoxValue.trim();
        if (!messageContent) return;

        const timestamp = new Date().getTime();

        const message = {
            content: messageContent,
            timestamp: timestamp,
            sender: auth.userid,
        };

        setChats((prevChats) => [
            ...prevChats,
            { content: messageContent, timestamp, sendOut: true, isAccumulated: false }
        ]);

        if (!wsRef.current || wsRef.current.readyState === WebSocket.CLOSED) {
            try {
                await initWs();
            } catch (error) {
                console.error("Failed to initialize WebSocket:", error);
                return;
            }
        }

        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify(message));
        } else {
            alert("Connection not open. Attempting to reconnect...");
        }

        // Clear the text box after sending the message
        setTextBoxValue("");
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSendClick();
        }
    };

    return (
        <div>
            <h1>ChatRoom for Medical AI Chats</h1>
            <div className="chatArea">
                {chats.map((chat, index) => (
                    <ChatBubble
                        key={index}
                        content={chat.content}
                        self_flag={!chat.sendOut}
                        timestamp={chat.timestamp}
                        sender={!chat.sendOut && !chat.sendOut ? { pfp: pfp["MedicalAiChat"], name: name["MedicalAiChat"] } : undefined}
                    />
                ))}
            </div>
            <div className="text-box-container chatInput">
                <input
                    type="text"
                    value={textBoxValue}
                    className="chatInputBox"
                    onChange={handleTextBoxChange}
                    onKeyDown={handleKeyPress}
                    placeholder="Type something..."
                />
                <div className="sendButton" onClick={handleSendClick}>
                    Send
                </div>
            </div>
        </div>
    );
};

export default MedicalAiChat;
