import React, { useContext } from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import style from "./detail.module.css";
import Carousel from "react-bootstrap/Carousel";
import "boxicons/css/boxicons.min.css";
import { AuthContext } from "../context/AuthContext";
import { findAllByTestId } from "@testing-library/react";


const Detail = (props) => {

  const { auth } = useContext(AuthContext);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemDetails, setItemDetails] = useState(null);
  
  const [itemActions, setItemActions] = useState(null);
  
  const [sellConfirm, setSellConfirm] = useState(0);    //mark as sold button
  const [sellMessage, setSellMessage] = useState("Mark as sold");

  let [url, setUrl] = useState("");
  let { iid } = useParams();

  url = `${process.env.REACT_APP_API_URL}api/item/get?iid=` + iid;

  const getAxios = () => {
    var config = {
      method: "get",
      url: url,
    };

    axios(config)
      .then(function (response) {
        setItemDetails(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    getAxios();
  }, [iid]);

  const handleIndexChange = (index) => {
    setCurrentIndex(index);
  };

  const pictureDisplay = () => {
    if (itemDetails === null) {
      return;
    }

    var photoArray = itemDetails.item.photo;

    let content = [];
    photoArray.forEach((photo, index) => {
      var imgsrc = "https://oss.umiuni.com/" + photo;

      content.push(
        <div
          key={index}
          onClick={() => {
            handleIndexChange(index);
          }}
          className={style.carouselItemContainer}
          style = {{
            boxShadow: (index === currentIndex) ? "0 0 7px rgba(0, 178, 255, 1)" : "",
          }}
        >
          <img src={imgsrc} className={style.carouselItem} />
        </div>
      );
    });
    return content;
  };

  const priceDisplay = () => {
    if (itemDetails === null) {
      return;
    }

    let content = [];

    content.push(
      <div className={style.priceInfo}>${itemDetails.item.price}</div>
    );

    if (!itemDetails.item.original_price) {
      return content;
    }

    var originalPrice = itemDetails.item.original_price;

    content.push(
      <>
        <div className={style.priceOriginal}>${originalPrice}</div>
      </>
    );

    return content;
  };

  const nameDisplay = () => {
    if (itemDetails === null) {
      return;
    }

    return (<h2>{itemDetails.item.name}</h2>);
  };

  const tagDisplay = () => {
    if (itemDetails === null) {
      return;
    }

    let content = [];
    var tagArray = itemDetails.item.tag;

    tagArray.forEach((tag, index) => {
      content.push(<button className={style.tagButton}>{tag}</button>);
    });

    return content;
  };

  const publisherDisplay = () => {
    if (itemDetails === null) {
      return;
    }

    let content = [];

    var publisherUsername = itemDetails.seller.username;


    return (
      <a href = "/" className = {style.anchor}>
      <div className = {style.publisherContainer}>
        <img
          src={`https://oss.umiuni.com/${itemDetails.seller.avatar}`}
          className={style.sellerAvatar}
        />
        <div>{publisherUsername}</div>
      </div>
      </a>
    );
  };

  const discriptionDisplay = () => {
    if (itemDetails === null) {
      return;
    }

    return itemDetails.item.description;
  };

  const emailDisplay = () => {
    if (itemDetails === null) {
      return;
    }

    return itemDetails.seller.email;
  };

  const contactInfoDisplay = () => {
    if (itemDetails === null) {
      return;
    }

    return itemDetails.item.contact;
  };

  const markSold = () => {
    console.log("selling");

    const payload = {
      userid: auth.userid,
      session: auth.session,
      iid: iid,
      status: 2
    };

    axios.post(`${process.env.REACT_APP_API_URL}api/item/set`, payload)
    .then((response) => {
      console.log(response);
      window.location.reload();
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    if(itemDetails) {
      actionButtonsDisplay(); 
    }
  }, [sellMessage, itemDetails, sellConfirm]);

  useEffect(() => {
    if(sellConfirm === 1) {
      setSellMessage("Confirm");
    } else if(sellConfirm === 2) {
      markSold();
    }
  }, [sellConfirm]);

  const handleSell = () => {
    if(sellConfirm === 0) {
      setSellConfirm(1);
    } else if(sellConfirm === 1) {
      setSellConfirm(2);
    }
  }

  const actionButtonsDisplay = () => {
    let buttons = [];

    if (itemDetails.item.status === 1 && auth.userid === itemDetails.seller.userid) {
      buttons.push(
        <button 
          className = {style.soldButton}
          onClick = {handleSell}
        >{sellMessage}</button>
      );
    } else {              //add actions for draft
      buttons.push(null);
    }

    setItemActions(buttons);
  }

  return (
    <>
      <div className={style.detailPage}>
        <div className = {style.imageSelectPanel}>
          {pictureDisplay(itemDetails)}
        </div>
        <div className = {style.carouselContainer}>
          <Carousel
            activeIndex={currentIndex}
            onSelect={handleIndexChange}
            className = {style.carousel}
            prevIcon={<span className={`carousel-control-prev-icon ${style.carouselArrow}`} />}
            nextIcon={<span className={`carousel-control-next-icon ${style.carouselArrow}`} />}
          >
            {itemDetails &&
              itemDetails.item.photo.map((photo, i) => {
                return (
                  <Carousel.Item key={i}>
                    <img
                      src={`https://oss.umiuni.com/${photo}`}
                      alt={`Slide ${i}`}
                      className = {style.carouselImage}
                    />
                  </Carousel.Item>
                );
              })
            }
          </Carousel>
        </div>
        <div className = {style.infoDiv}>
          <div className = {style.infoInternal}>
            {nameDisplay()}
            {itemActions}
          </div>
          
          <div className = {style.infoInternal}>
            <div className = {style.priceContainer}>{priceDisplay()}</div>
          </div>
          <div className = {style.infoInternal}>{tagDisplay()}</div>
          <div className = {style.infoInternal}>
            <div>Published by</div>
            {publisherDisplay()}
          </div>
          <div className={style.infoInternal}>
            <div>Description</div>
            {discriptionDisplay()}
          </div>
          <div className = {style.infoInternal}>
            <div>Contact Information</div>
            {contactInfoDisplay()}
            <br />
            {emailDisplay()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Detail;
