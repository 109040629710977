import React from "react";
import style from "./content_sharing_upload.module.css";
import { AuthContext } from "../context/AuthContext";
import { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const ContentSharingUpload = () => {
    const { auth } = useContext(AuthContext);
    const [photo, setPhoto] = useState(null);
    const [id, setId] = useState(null);
    const fileInputRef = useRef(null);
    const navigate = useNavigate();   

    useEffect(() => {
        if (auth.userid) {
            let data = JSON.stringify({
                "encodedString": auth.userid
            });
    
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://us.umiuni.com:5002/api/user/decode_userid',
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : data
            };
        
            axios.request(config)
            .then((response) => {
                const result = response.data.result;
                setId(result);
            })
            .catch((error) => {
                console.log(error);
            });
        } else {
            navigate('/login');
        }
    }, []);

    const handlePhoto = (event) => {
        const file = event.target.files[0];
        setPhoto(file);
        console.log(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formElement = document.getElementById("post-form");
        const formInputs = formElement.elements;

        const formdata = new FormData();
        
        formdata.append('userid', id);
        formdata.append('pwd', 'testing');
        formdata.append('image', photo);
        for (let i = 0; i < formInputs.length; i++) {
            const input = formInputs[i];
            if (input.value !== "") {
              formdata.append(input.name, input.value);
            }
        }

        const requestOptions = {
            method: "POST",
            body: formdata,
	    redirect: "follow"
        };

        fetch("https://python.umiuni.com:5555/umiuni_content_sharing", requestOptions)
        .then((response) => response.text())
        .then((result) => {
            console.log(result); 
            formElement.reset(); 
            setPhoto(null); 
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        })
        .catch((error) => console.error(error));
    };

    return (
        <div className={style.mainContainer}>
            <div className={style.uploadContainer}>
                <div className={style.uploadSection}>
                    <input type="file" accept="image/*" onChange={handlePhoto} ref={fileInputRef}/>
                </div>
                <div className={style.formContainer}>
                    <form id="post-form">
                        <div className={style.input}>
                            <label>Name</label>
                            <input type="text" name="content_name" />
                        </div>
                        <div className={style.input}>
                            <label>Page</label>
                            <input type="number" name="page_num" />
                        </div>
                        <div className={style.input}>
                            <label>Text</label>
                            <input type="text" name="text" />
                        </div>
                    </form>
                    <button onClick={handleSubmit} className={style.updateButton}>Submit</button>
                </div>
            </div>
        </div>
    );
};

export default ContentSharingUpload;
