import React, { useContext } from 'react';
import './TopUp.css';
import { AuthContext } from "../context/AuthContext";

const TopUp = () => {
    const { auth } = useContext(AuthContext);
    const { userid, session } = auth;

    // Authorization check: Show a message if the user is not logged in
    if (!userid || !session) {
        return (
            <div className="container">
                <p>You need to log in before making a payment.</p>
                <a href="/login">
                    <button className="login-btn">Login</button>
                </a>
            </div>
        );
    }

    return (
        <div className="container">
            {/* Line of Words */}
            <div className="line-of-words">Choose your top-up option below:</div>

            {/* Tabs Wrapper */}
            <div className="tabs-wrapper">
                <div className="tabs-container">
                    {/* Tab 1 */}
                    <form method="POST" action="https://us.umiuni.com:5002/api/payment/charge/stripe/vip">
                        <input type="hidden" name="userId" value={userid} />
                        <input type="hidden" name="session" value={session} />
                        <input type="hidden" name="totalPrice" value="99" />
                        <input type="hidden" name="increase_balance" value="100" />
                        <button className="tab">
                            <div className="tab-image">
                                <img src="/static/gems-99.jpeg" alt="Tab 1" />
                            </div>
                            <div className="tab-info">
                                <h3>$0.99</h3>
                                <p>Get 100 XP!</p>
                            </div>
                        </button>
                    </form>

                    {/* Tab 2 */}
                    <form method="POST" action="https://us.umiuni.com:5002/api/payment/charge/stripe/vip">
                        <input type="hidden" name="userId" value={userid} />
                        <input type="hidden" name="session" value={session} />
                        <input type="hidden" name="totalPrice" value="199" />
                        <input type="hidden" name="increase_balance" value="300" />
                        <button className="tab">
                            <div className="tab-image">
                                <img src="/static/gems-199.jpeg" alt="Tab 2" />
                            </div>
                            <div className="tab-info">
                                <h3>$1.99</h3>
                                <p>Get 300 XP!</p>
                            </div>
                        </button>
                    </form>

                    {/* Tab 3 */}
                    <form method="POST" action="https://us.umiuni.com:5002/api/payment/charge/stripe/vip">
                        <input type="hidden" name="userId" value={userid} />
                        <input type="hidden" name="session" value={session} />
                        <input type="hidden" name="totalPrice" value="499" />
                        <input type="hidden" name="increase_balance" value="700" />
                        <button className="tab">
                            <div className="tab-image">
                                <img src="/static/gems-499.jpeg" alt="Tab 3" />
                            </div>
                            <div className="tab-info">
                                <h3>$4.99</h3>
                                <p>Get 700 XP!</p>
                            </div>
                        </button>
                    </form>

                    {/* Tab 4 */}
                    <form method="POST" action="https://us.umiuni.com:5002/api/payment/charge/stripe/vip">
                        <input type="hidden" name="userId" value={userid} />
                        <input type="hidden" name="session" value={session} />
                        <input type="hidden" name="totalPrice" value="999" />
                        <input type="hidden" name="increase_balance" value="4900" />
                        <button className="tab">
                            <div className="tab-image">
                                <img src="/static/gems-999.jpeg" alt="Tab 4" />
                            </div>
                            <div className="tab-info">
                                <h3>$9.99</h3>
                                <p>Get 4900 XP!</p>
                            </div>
                        </button>
                    </form>

                    {/* Tab 5 */}
                    <form method="POST" action="https://us.umiuni.com:5002/api/payment/charge/stripe/vip">
                        <input type="hidden" name="userId" value={userid} />
                        <input type="hidden" name="session" value={session} />
                        <input type="hidden" name="totalPrice" value="1999" />
                        <input type="hidden" name="increase_balance" value="34300" />
                        <button className="tab">
                            <div className="tab-image">
                                <img src="/static/gems-1999.jpeg" alt="Tab 5" />
                            </div>
                            <div className="tab-info">
                                <h3>$19.99</h3>
                                <p>Get 34300 XP!</p>
                            </div>
                        </button>
                    </form>

                    {/* Tab 6 */}
                    <form method="POST" action="https://us.umiuni.com:5002/api/payment/charge/stripe/vip">
                        <input type="hidden" name="userId" value={userid} />
                        <input type="hidden" name="session" value={session} />
                        <input type="hidden" name="totalPrice" value="4999" />
                        <input type="hidden" name="increase_balance" value="343000" />
                        <button className="tab">
                            <div className="tab-image">
                                <img src="/static/gems-4999.jpeg" alt="Tab 6" />
                            </div>
                            <div className="tab-info">
                                <h3>$49.99</h3>
                                <p>Get 343000 XP!</p>
                            </div>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default TopUp;
